<!-- eslint-disable vue/multi-word-component-names -->
<template>
	<div class="basic-component compEl contact">
		<Menu />
		<div class="content">
			<svg viewBox="0 0 100 100" class="svgBlobs" v-if="!isSafari">
				<path :d="path" fill="white"></path>
			</svg>
			<div class="container">
				<a :href="mailLink" :class="{ 'reactive' : isReactive }" class="mail contact__section" ref="mail" @mouseenter="mailMouse" @mouseleave="mouseLeave" @click="(e) => { activateClick(e, 'mail') }">
					<span v-for='item in 2' :key="item" class="cross"><img  v-if="isMobile" :src="halfCross" alt=""></span>
					<div class="sub-container">
						<span class="border" v-for="item in 4" :key="item"></span>
						<img :src="mailIgm" alt="">
					</div>
				</a>
				<a :href="telegramLink" :class="{ 'reactive' : isReactive }" target="_blank" class="telegram contact__section" @mouseenter="decypher" @mouseleave="crypt" @click="(e) => { activateClick(e, 'telegram') }" ref="telegram">
					
					<span v-for='item in 4' :key="item" class="cross"><img :src="isMobile && item < 3 ? halfCross : cross" alt=""></span>
					<div class="caption"><ScrambleComp class="scramble" :val='activateText' ref="activateScramble" :appearTime="0.03" /></div>
						
						<h1 class="sub-container">
							<span class="border" v-for="item in 4" :key="item"></span>
							<span class="hidden">{{ telegramText }}</span>
							<ScrambleComp :val='telegramText' ref="telegramScramble" :appearTime="0.03" /></h1>
				</a>
				<a :href="twitterLink" :class="{ 'reactive' : isReactive }" target="_blank" class="twitter contact__section" ref="twitter" @mouseenter="twitterMouse" @mouseleave="mouseLeave" @click="(e) => { activateClick(e, 'twitter') }">
					<span v-for='item in 2' :key="item" class="cross"><img  v-if="isMobile" :src="halfCross" alt=""></span>
					<div class="sub-container">
						<span class="border" v-for="item in 4" :key="item"></span>
						<img :src="twitterIgm" alt="">
					</div>
				</a>
			</div>
		</div>
		<div class="bottom-part">
			<span class="toWrite" v-html="unclassified"></span>
			<div class="right">
				<img :src="logo" alt="">
				<div class="coordinates toWrite" v-if="!isMobile">
					<span>{{ (cursorX * 10).toFixed(2)}} -BKD</span>
					<span>{{ (cursorY * 10).toFixed(2)}}°ST</span>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import blobshape from "blobshape";
import store from "@/assets/js/store";
import Menu from '@/components/Menu';
import mail from '@/assets/images/mail.png'
import twitter from '@/assets/images/twitter.png'
import cross from '@/assets/images/cross.png'
import halfCross from '@/assets/images/halfCross.png'
import ScrambleComp from '@/components/ScrambleComp'
import logo from '@/assets/images/logo.png'
import E from '@/assets/js/utils/E'
import GlobalEvents from '@/assets/js/utils/GlobalEvents'
import { useRouter } from 'vue-router'
import gsap from 'gsap'
export default {
  name: 'About-comp',
  components: {
	Menu,
	ScrambleComp
  },
  props: {
    data: Object
  },
  data () {
    return {
		path:'',
		isSafari: store.isSafari,
		mailIgm: mail,
		twitterIgm: twitter,
		cross: cross,
		halfCross: halfCross,
		telegramText: 'Groom lake',
		activateText: 'activate',
		scaleX: 1,
		scaleY: 1,
		xTranslate: 0,
		cursorX: 0.4,
		cursorY: -0.75,
		logo: logo,
		isMobile: store.isMobile,
		twitterLink: 'https://x.com/0xGroomLake',
		mailLink: 'mailto:ops@groomla.ke',
		telegramLink: "http://t.me/Cerberus_GL",
		unclassified: "Top Secret//SI//Rel to GLPMC, fvey",
		isReactive: false,
		linkDelayed: false
	}
  },
  created () {
	
  },
  beforeUnmount(){
	E.off(GlobalEvents.RESIZE, this.onResize)
	store.RAFCollection.remove(this.onRaf, 0)
	this.isLeaving = true
	E.off('transitionEnd', this.setup)
  },
  mounted () {
	E.on('transitionEnd', this.setup)
	this.scaleOffset = this.isMobile ? [1.02, 1.05] : [1.02, 1.05]
	E.on(GlobalEvents.RESIZE, this.onResize)
	store.RAFCollection.add(this.onRaf, 0)
	if(!this.isSafari){
		let path = blobshape({ size: 100, growth: 1, edges: 10, seed: null });
		this.path = path.path
	}
	this.router = useRouter()

	this.router.beforeEach(() => {
		this.mouseLeave()
	})
	
  },
  methods: {
	setup(){
		this.isReactive = true
	},
	decypher(){
		console.log(this.isReactive)
		if (this.isLeaving) return
		if(this.outTimeout) clearTimeout(this.outTimeout)
		this.$refs.telegramScramble?.decypher('in')
		this.$refs.activateScramble?.decypher('in')

		if(!this.$refs.telegram?.size) this.$refs.telegram.size = this.$refs.telegram.getBoundingClientRect()
		const mobileOffset = this.isMobile ? 80 : 0
		const scaleX = (this.$refs.telegram.size.width - mobileOffset) /store.window.w
		const scaleY = this.$refs.telegram.size.height /store.window.h
		const xTranslate = this.$refs.telegram.size.x + this.$refs.telegram.size.width * 0.5 - store.window.w * 0.5
		const yTranslate = this.$refs.telegram.size.y + this.$refs.telegram.size.height * 0.5 - store.window.h * 0.5

		gsap.to(this, {scaleX: scaleX, scaleY:scaleY, xTranslate: xTranslate, yTranslate: yTranslate, onUpdate: () => {
			document.documentElement.style.setProperty('--scaleX', this.scaleX * this.scaleOffset[0])
			document.documentElement.style.setProperty('--scaleY', this.scaleY * this.scaleOffset[1])
			document.documentElement.style.setProperty('--TX', this.xTranslate + 'px')
			document.documentElement.style.setProperty('--TY', this.yTranslate + 'px')

		} })
	},
	crypt(){
		if (this.isLeaving) return
		this.$refs.telegramScramble.decypher('out')
		this.$refs.activateScramble.decypher('out')
		this.mouseLeave()
	},
	mailMouse(){
		if (this.isLeaving) return
		if(this.outTimeout) clearTimeout(this.outTimeout)
		if(!this.$refs.mail.size) this.$refs.mail.size = this.$refs.mail.getBoundingClientRect()
		const scaleX = this.$refs.mail.size.width /store.window.w
		const scaleY = this.$refs.mail.size.height /store.window.h
		const xTranslate = this.$refs.mail.size.x + this.$refs.mail.size.width * 0.5 - store.window.w * 0.5
		const yTranslate = this.$refs.mail.size.y + this.$refs.mail.size.height * 0.5 - store.window.h * 0.5

		gsap.to(this, {scaleX: scaleX, scaleY:scaleY, xTranslate: xTranslate, yTranslate: yTranslate, onUpdate: () => {
			document.documentElement.style.setProperty('--scaleX', this.scaleX * this.scaleOffset[0])
			document.documentElement.style.setProperty('--scaleY', this.scaleY * this.scaleOffset[1])
			document.documentElement.style.setProperty('--TX', this.xTranslate + 'px')
			document.documentElement.style.setProperty('--TY', this.yTranslate + 'px')

		} })
	},
	twitterMouse(){
		if (this.isLeaving) return
		if(this.outTimeout) clearTimeout(this.outTimeout)
		if(!this.$refs.twitter.size) this.$refs.twitter.size = this.$refs.twitter.getBoundingClientRect()
		const scaleX = this.$refs.twitter.size.width /store.window.w
		const scaleY = this.$refs.twitter.size.height /store.window.h
		const xTranslate = this.$refs.twitter.size.x + this.$refs.twitter.size.width * 0.5 - store.window.w * 0.5
		const yTranslate = this.$refs.twitter.size.y + this.$refs.twitter.size.height * 0.5 - store.window.h * 0.5
		gsap.to(this, {scaleX: scaleX, scaleY:scaleY, xTranslate: xTranslate,yTranslate: yTranslate, onUpdate: () => {
			document.documentElement.style.setProperty('--scaleX', this.scaleX * this.scaleOffset[0])
			document.documentElement.style.setProperty('--scaleY', this.scaleY * this.scaleOffset[1])
			document.documentElement.style.setProperty('--TX', this.xTranslate + 'px')
			document.documentElement.style.setProperty('--TY', this.yTranslate + 'px')

		} })
	},

	mouseLeave(){
		this.outTimeout = setTimeout(() => {
			const scaleX = 1
			const scaleY = 1
			const xTranslate = 0
			const yTranslate = 0

			gsap.to(this, {scaleX: scaleX, scaleY:scaleY, xTranslate: xTranslate, yTranslate: yTranslate, onUpdate: () => {
				document.documentElement.style.setProperty('--scaleX', this.scaleX)
				document.documentElement.style.setProperty('--scaleY', this.scaleY)
				document.documentElement.style.setProperty('--TX', this.xTranslate + 'px')
				document.documentElement.style.setProperty('--TY', this.yTranslate + 'px')

			} })
		}, 100)
		
	},
	activateClick(e, dest){
		if(this.isMobile){
			if(!this.linkDelayed) {
				if(!this.isSafari) e.preventDefault();
				gsap.delayedCall(1, () => {
					this.linkDelayed = true
					this.$refs[dest].click()
					this.$el.click()
					this.mouseLeave()

				})
			} else {
				this.linkDelayed = false
			}
		}
	},
	onRaf(){
		this.cursorX = store.pointer.glNormalized.x
		this.cursorY = store.pointer.glNormalized.y
	},
	onResize(){
		this.$refs.telegram.size = undefined
		this.$refs.mail.size = undefined
		this.$refs.twitter.size = undefined
	}
  }
}
</script>
<style lang="less">
	@import '../../assets/less/_imports.less';
	@import "./style.less";
</style>