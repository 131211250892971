<!-- eslint-disable vue/multi-word-component-names -->
<template>
	<div class="basic-component compEl not-found">
		<div class="content">
			<div class="title-container">
				<span class="cross" v-for="item in 4" :key="item">
					<img :src="cross" alt="">
				</span>
				<h1>
					404
				</h1>

				<div class="typewrite-content" ref="typewrite">
					<div class="tick" ref="tick">_</div>

					<span class="toWrite typeWrite">Error occurred</span>
					<span class="toWrite typeWrite "> {{ returnText }} </span>

					<router-link to="ops" v-if="isMobile" class="toWrite typeWrite choices margin"><span>**ops_page**</span></router-link>
					<div v-else class="toWrite typeWrite choices margin"><span>**ops_page**</span><span v-if="!isMobile">Type: “Ops”</span></div>

					<router-link to="activate" v-if="isMobile" class="toWrite typeWrite choices "><span>**activate_page**</span></router-link>
					<div  v-else class="toWrite typeWrite choices"><span>**activate_page**</span><span v-if="!isMobile">Type: "activate"</span></div>

					<router-link to="about" v-if="isMobile" class="toWrite typeWrite choices "><span>**about_page**</span></router-link>
					<div v-else class="toWrite typeWrite choices "><span>**about_page**</span><span v-if="!isMobile">Type: “About”</span></div>

					<router-link to="/" v-if="isMobile" class="toWrite typeWrite margin">Press [Home] To return to homepage</router-link>
					<span  v-else class="toWrite typeWrite margin">Type “back” to return to the main page or press [enter]</span>

					<div class="error-container" ref="error">

					</div>
					<span class="typed" ref='typed'>{{ typed }}</span>
				</div>
			</div>
			<div class="top-part">
				<div class="coordinates toWrite">
					<span>{{ topText }}</span>
				</div>
			</div>
			<div class="bottom-part">
				<span class="toWrite" v-html="classified"></span>
				<div class="right">
					<img :src="logo" alt="">
					<!-- <div class="coordinates toWrite" v-if="!isMobile">
						<span>{{ (cursorX * 10).toFixed(2)}} -BKD</span>
						<span>{{ (cursorY * 10).toFixed(2)}}°ST</span>
					</div> -->
				</div>
			</div>
		</div>
	</div>
</template>
<script>

import thinCross from '@/assets/images/thinCross.png'
import logo from '@/assets/images/logo.png'
import SplitType from 'split-type'
import gsap from 'gsap'
import store from '@/assets/js/store'
import { useRouter } from 'vue-router'
import E from '@/assets/js/utils/E'
export default {
  name: 'Basic-comp',
  components: {},
  props: {
    data: Object,
  },
  data () {
    return {
		cross: thinCross,
		classified: '//unclassified information missing',
		logo: logo,
		topText: 'Secret//Rel to glpmc, fvey',
		returnText: '<return index>',
		typed: '',
		isMobile: store.isMobile
	}
  },
  created () {
	this.router = useRouter()
  },
  beforeUnmount(){
	E.off('transitionEnd', this.setup)

  },
  mounted () {
	E.on('transitionEnd', this.setup)

	this.router.beforeEach(() => {
		window.removeEventListener('keydown',this.keyDownEvent)
	})
  },
  methods: {
	setup(){
		this.setWrite(Array.from(this.$el.querySelectorAll('.typeWrite')))
	},
	setWrite(arr){
		const that = this
		// this.$refs.typewrite.size = this.$refs.typewrite.getBoundingClientRect()
		this.timeline = gsap.timeline({
			paused: true,
			onComplete: () => {
				this.keyDownEvent = window.addEventListener('keydown', (el) => {
					this.$refs.tick.style.display = 'none'
					this.$refs.typed.style.display = 'block'

					if(el.metaKey || el.ctrlKey) return
					switch (el.key) {
						case 'Backspace':
							this.typed = this.typed.substring(0, this.typed.length - 1);
							
							break;
						case 'Enter':
							this.checkCommand()
						break;
						default:
							this.typed += el.key
							break;
					}
				})
			}
		})

		arr.forEach(el => {
			el.split = new SplitType(el, {types: 'char, word'})
			el.split.chars.forEach(el => {
				el.position = el.getBoundingClientRect()
			})
			el.style.display = 'none'
			gsap.set(el.split.chars, {display: 'none'})
			

			this.timeline.to(el.split.chars, {
				display: 'inline',
				ease:'linear.none',
				duration: 0.001,
				stagger: {
					each: 0.02,
					onComplete: function() {
						that.$refs.tick.style.left = this.targets()[0].position.x - that.$refs.typewrite.size.x+ 'px'
					}
				},
				onStart: () => {
				el.style.display = 'block'
			}})
		});

		gsap.delayedCall(1, () => {
			this.$refs.typewrite.size = this.$refs.typewrite.getBoundingClientRect()
			gsap.set(arr, {opacity: 1})
			this.$refs.typewrite.style.opacity = 1
			this.timeline.play()
		})
	},
	checkCommand(){
		switch (this.typed.toLowerCase()) {
			case 'about':
				this.router.push('about')
				break;
			case 'ops':
				this.router.push('ops')
				break;
			case 'back':
				this.router.push('/')
				break;
			case 'activate':
				this.router.push('activate')
				break;
			default:
				// eslint-disable-next-line no-case-declarations
				const errorEl = document.createElement('span')
				errorEl.innerHTML = 'Error occurred'
				this.$refs.error.appendChild(errorEl)
				this.typed = ''
				break;
		}
	}
  }
}
</script>
<style lang="less">
@import '../../assets/less/_imports.less';
@import "./style.less";
</style>