<!-- eslint-disable vue/multi-word-component-names -->
<template>
	<div class="basic-component compEl logoTransition">
		<div class="content">
			<div class="imageContainer">
				<img :src="logo" alt="">
			</div>
		</div>
	</div>
</template>
<script>
import logo from '@/assets/images/logo.png'
export default {
  name: 'Basic-comp',
  components: {},
  props: {
    data: Object
  },
  data () {
    return {
		logo: logo
	}
  },
  created () {
  },
  mounted () {
  },
  methods: {
  }
}
</script>
<style lang="less">
	@import '../../assets/less/_imports.less';
	@import "./style.less";
</style>