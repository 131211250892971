<!-- eslint-disable vue/multi-word-component-names -->
<template>
	<div class="basic-component compEl countdown">
		<div class="content">
			<svg viewBox="0 0 100 100" class="svgBlobs" v-if="!isSafari">
				<path :d="path" fill="white"></path>
			</svg>
			<canvas class="clock" ref="clock"></canvas>
			<div class="hoverTrigger" ref="hoverTrigger" @mouseenter="() => {setHover('in')}" @mouseleave="() => {setHover('out')}"></div>
			<div class="countdown-el">
				<div class="subTitle caption">
					<span v-if="!hover">countdown ends in</span>
					<span v-else><ScrambleComp val="___groom lake___" :appearTime="0.1" ref="groomLake" /></span>
				</div>
				<h1 class="value" v-if="!hover" ref="title">
					<span>{{ this.days }}:</span>
					<span>{{ this.hours }}:</span>
					<span>{{ this.minutes }}:</span>
					<span>{{ this.seconds }}</span>
				</h1>
				<h1 v-else>
					<ScrambleComp :val='denied' :appearTime="0.1" ref="scramble" />
				</h1>
			</div>
			<div class="verticalLines up">
				<span></span><span v-if="!isMobile"></span><span v-if="!isMobile"></span> <span v-if="!isMobile"></span>
			</div>
			<div class="verticalLines down">
				<span></span><span v-if="!isMobile"></span><span v-if="!isMobile"></span> <span v-if="!isMobile"></span>
			</div>
			<div class="bottom-part">
				<span class="toWrite" v-html="classified"></span>
				<div class="right">
					<img :src="logo" alt="">
					<!-- <div class="coordinates toWrite" v-if="!isMobile">
						<span>04-BKD</span>
						<span>-0.75°ST</span>
					</div> -->
				</div>
			</div>
			<div class="middlePart" v-if="!isMobile">
				<div class="left">
					<span>FD</span>
					<span>0AII</span>
				</div>
				<div class="middle"></div>
				<div class="right">
					<span>RWY<br/>3880</span>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import blobshape from "blobshape";
import store from "@/assets/js/store";
import logo from '@/assets/images/logo.png'
import { DateTime } from "luxon";
import E from '@/assets/js/utils/E'
import GlobalEvents from '@/assets/js/utils/GlobalEvents'
import ScrambleComp from '@/components/ScrambleComp'
import gsap from 'gsap'
export default {
  name: 'About-comp',
  components: {
	ScrambleComp
  },
  props: {
    data: Object
  },
  data () {
    return {
		classified: "Controlled Unclassified Information",
		denied: "access denied",
		logo: logo,
		path:'',
		days: 0,
		hours: 0,
		minutes: 0,
		seconds: 0,
		isSafari: store.isSafari,
		isMobile: store.isMobile,
		hover: false,
		circleLength: 1	
	}
  },
  created () {

  },
  mounted () {
	this.titleSize = this.$refs.title.getBoundingClientRect()
	gsap.set(this.$refs.hoverTrigger, { width: this.titleSize.width, height: this.titleSize.height})
	E.on(GlobalEvents.RESIZE, this.onResize)
	this.setCanvas()
	this.setCountdown()

	if(!this.isSafari){
		let path = blobshape({ size: 100, growth: 1, edges: 10, seed: null });
		this.path = path.path
	}
	
  },
  methods: {

	setCanvas(){
		this.ctx = this.$refs.clock.getContext("2d")
		if(store.window.h < store.window.w){
			this.$refs.clock.width = store.window.w * 2
			this.$refs.clock.height = store.window.h * 2

		} else {
			this.$refs.clock.width = store.window.w * 2
			this.$refs.clock.height = store.window.h * 2
		}
		this.ctx.lineWidth = 5;
		this.ctx.strokeStyle = "#545854";
		this.ctx.fillStyle = "#545854";
	},


	updateCanvas(state){
		
		if(store.window.h < store.window.w){
			this.radius = this.isMobile ? this.$refs.clock.height * 0.45  : this.$refs.clock.height * 0.37 - 10
		} else {
			this.radius = this.isMobile ? this.$refs.clock.width * 0.45  : this.$refs.clock.width * 0.37 - 10
		}

		this.$el.style.setProperty('--circleRad', (this.$refs.clock.height * 0.37 - 10)*0.5 + 'px')

		const hueStart = 120
		const saturation = 50
		const hueEnd = 170
		const lightness = 40

		this.gradient = this.ctx.createLinearGradient( this.radius * 5, 0, 0, 0 );
		this.gradient.addColorStop( 0, 'hsla( ' + hueStart + ', ' + saturation + '%, ' + lightness + '%, 1 )' );
		this.gradient.addColorStop( 1, 'hsla( ' + hueEnd + ', ' + saturation + '%, ' + lightness + '%, 0.1 )' );
		
		gsap.fromTo(this, {roundRadius: -Math.PI * 0.5 + this.initDeg * 2 * Math.PI}, {roundRadius: Math.PI * 1.5, ease: 'none', duration: 60 - 60 * this.initDeg, repeat: state === 'init' ? 0 : -1, onUpdate: () => {
			this.ctx.clearRect(0, 0, this.$refs.clock.width, this.$refs.clock.height)

			this.ctx.beginPath();
			this.ctx.arc(
				this.$refs.clock.width * 0.5,
				this.$refs.clock.height * 0.5,
				this.radius,
				- Math.PI * 0.5,
				(1 - this.circleLength) *2 * Math.PI - Math.PI * 0.5)
				
			this.ctx.stroke();
			this.ctx.closePath();

			this.ctx.beginPath();
			this.ctx.globalAlpha = 0.1
			this.ctx.moveTo(this.$refs.clock.width * 0.5, 0)
			this.ctx.lineTo(this.$refs.clock.width * 0.5, this.$refs.clock.height * 0.5)
			this.ctx.arc(this.$refs.clock.width * 0.5, this.$refs.clock.height * 0.5, this.radius * 5, -Math.PI * 0.5,this.roundRadius);
			this.ctx.lineTo(this.$refs.clock.width * 0.5, this.$refs.clock.height * 0.5)
			// this.ctx.arc(this.$refs.clock.width * 0.5, this.$refs.clock.width * 0.5, radius , this.roundRadius, - Math.PI * 0.5);
			// this.ctx.fillStyle =  this.gradient
			this.ctx.fill();
			this.ctx.closePath();
			this.ctx.globalAlpha = 1
			this.ctx.fillStyle = "#545854"
			const dashNum = this.isMobile ? 13 : 29
			const radiusOffset = this.isMobile ? 20: 40
			for(let i = 0; i< dashNum; i++){
					if(this.isMobile){
						if(i === 3 || i === 9) continue
					} else{
						if(i === 7 || i === 21) continue
					}
					this.ctx.beginPath();
					this.ctx.translate(this.$refs.clock.width * 0.5 + (this.radius - radiusOffset) * Math.cos(i * (2*Math.PI) / (dashNum - 1)), this.$refs.clock.height * 0.5 + (this.radius - radiusOffset) * Math.sin(i * (2*Math.PI) / (dashNum - 1)))
					this.ctx.rotate(i * (2*Math.PI) / (dashNum - 1))
					if(this.isMobile){
						this.ctx.rect(0, 0 , 20, 2)

					} else {
						this.ctx.rect(0, 0 , 40, 1)
					}
					this.ctx.fill()
					this.ctx.closePath();
					this.ctx.setTransform(1, 0, 0, 1, 0, 0);
				}
		}, onComplete: () => {
			this.initDeg = 0
			if(state === 'init') this.updateCanvas()
		}})
	},

	setCountdown(){
	// var countDownDate = new Date("Sept 9, 2024 15:37:25").getTime();
	var countDownDate = DateTime.fromObject({month: 9, day: 11, hour: 12 }, { zone: 'America/New_York', numberingSystem: 'beng'})
	var countDebut = DateTime.fromObject({month: 8, day: 30, hour: 7 }, { zone: 'America/New_York', numberingSystem: 'beng'})
	var now = new Date().getTime();
	// Find the distance between now and the count down date
	var distance = countDownDate - now;
	var totalDistance = countDownDate - countDebut;
	

	// this.circleLength = distance / totalDistance
	// Time calculations for days, hours, minutes and seconds
	this.days = Math.floor(distance / (1000 * 60 * 60 * 24));
	this.hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
	this.minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
	this.seconds = Math.floor((distance % (1000 * 60)) / 1000);
	if(this.days < 10) this.days = "0" +  this.days.toString()
	if(this.hours < 10) this.hours = "0" +  this.hours.toString()
	if(this.minutes < 10) this.minutes = "0" +  this.minutes.toString()
	if(this.seconds < 10) this.seconds = "0" +  this.seconds.toString()

	this.initDeg = (60 - this.seconds)/60
	this.updateCanvas('init')

	gsap.to(this, { circleLength: distance / totalDistance, duration: 2, ease: 'power3.out', onComplete: () => {this.updateDuration = true}})

	setInterval(() => {
		// Get today's date and time
		var now = new Date().getTime();
		// Find the distance between now and the count down date
		var distance = countDownDate - now;

		if(this.updateDuration) this.circleLength = distance / totalDistance
		// Time calculations for days, hours, minutes and seconds
		this.days = Math.floor(distance / (1000 * 60 * 60 * 24));
		this.hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
		this.minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
		this.seconds = Math.floor((distance % (1000 * 60)) / 1000);

		if(this.days < 10) this.days = "0" +  this.days.toString()
		if(this.hours < 10) this.hours = "0" +  this.hours.toString()
		if(this.minutes < 10) this.minutes = "0" +  this.minutes.toString()
		if(this.seconds < 10) this.seconds = "0" +  this.seconds.toString()
		// this.updateCanvas()
		}, 1000);
	},
	setHover(way){
		if(way === 'out'){
			if(this.$refs.scramble)this.$refs.scramble.decypher('out')
			if(this.$refs.groomLake)this.$refs.groomLake.decypher('out')
			if(this.hoverTimeout) clearInterval(this.hoverTimeout)
			this.hoverTimeout = setTimeout(() => {
				this.hover = false
			}, 700)
			
		} else {
			this.hover = true
			this.$nextTick(() => {
				if(this.hoverTimeout) clearInterval(this.hoverTimeout)
				this.$refs.scramble.decypher('in')
				this.$refs.groomLake.decypher('in')
			})
		}
	},
	onResize(){
		this.setCanvas()
		if(store.window.h < store.window.w){
			this.radius = this.isMobile ? this.$refs.clock.height * 0.45  : this.$refs.clock.height * 0.37 - 10
		} else {
			this.radius = this.isMobile ? this.$refs.clock.width * 0.45  : this.$refs.clock.width * 0.37 - 10
		}
	}
  }
}
</script>
<style lang="less">
	@import '../../assets/less/_imports.less';
	@import "./style.less";
</style>