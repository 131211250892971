<!-- eslint-disable vue/multi-word-component-names -->
<template>
	<div class="menu" :class="[{'home-menu': home }, {'open': open}]">
		<button class="title" @click="toggle"><div class="toggle"><img :src="chevron" alt=""></div><span class="title--name">menu</span></button>
		<span class="border"></span>
		<nav ref="nav">
			<menuItem v-for="(item, i) in itemIndex" ref="navItem" :key="i" :data="item" :int="itemIndex.length - i" />
		</nav>
		<span v-if="!home" class="border bottom" ref="borderBottom"></span>
	</div>

</template>
<script>
import store from '@/assets/js/store'
import menuItem from '@/components/menuItem'
import gsap from 'gsap'
import chevron from '@/assets/images/chevron.png'

export default {
  name: 'Menu-comp',
  components: {
	menuItem
  },
  props: {
    data: Object,
	home: Boolean
  },
  computed: {
	itemIndex: function(){
		return this.menuItems.filter(item => item.url !== this.$route.fullPath)
	}
  },
  data () {
    return {
		isMobile: store.isMobile,
		open: this.home,
		itemNumber: 8,
		chevron: chevron,
		menuItems: [
			{
				code: 'MBD',
				url: '/',
				name: 'home'
			},
			{
				code: 'BKD',
				url: '/ops',
				name: 'Ops'
			},
			{
				code: 'PMW',
				url: '/about',
				name: 'About'
			},
			{
				code: 'PKD',
				url: '/activate',
				name: 'Activate'
			}
		]
	}
  },
  created () {
  },
  mounted () {
	this.navLinks = this.$refs.nav.querySelectorAll('a')
	if(this.home){
		gsap.set(this.navLinks, { autoAlpha: 1})
	} else {
		gsap.set(this.navLinks, { autoAlpha: 0})
	}
  },
  methods: {
	desactivate(){
		this.$el.style.pointerEvents = "none"
	},
	toggle(){
		if(this.open){
			gsap.to(this.navLinks, { autoAlpha: 0, duration: 0.001, stagger: -0.1})
			this.$refs.navItem.forEach(el => {el.hide()})
			if(!this.home) this.$refs.borderBottom.style.opacity = '0'
		} else {
			gsap.to(this.navLinks, { autoAlpha: 1, duration: 0.001, stagger: 0.1, onComplete: () => {
				if(!this.home) this.$refs.borderBottom.style.opacity = '1'
			}})
			this.$refs.navItem.forEach(el => {el.appear()})
			
		}
		this.open = !this.open
	},
	getId(){
		this.itemNumber--
		if(this.itemNumber<0) return
		return this.itemNumber
	}
  }
}
</script>
<style lang="less">
@import '../../assets/less/_imports.less';
@import "./style.less";
</style>