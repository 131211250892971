<!-- eslint-disable vue/multi-word-component-names -->
<template>
	<router-link :to="data.url" :key="int" ref="navItem" @mouseover='hover' >
		<div class="left">
			<span v-if="!isMobile"><ScrambleComp :val='data.code' type="hover" :appearTime="0.1"  ref="scramble1"/></span>
			<span> <ScrambleComp :val="`/.${ int }`" type="hover" :appearTime="0.1"  ref="scramble2"/></span>
		</div>

		<span> <ScrambleComp :val="data.name" type="hover" :appearTime="0.1"  ref="scramble3"/></span>
	</router-link>
</template>
<script>
import store from '@/assets/js/store'
import ScrambleComp from '@/components/ScrambleComp'

export default {
  name: 'Basic-comp',
  components: {
	ScrambleComp
  },
  props: {
    data: Object,
	int: Number
  },
  data () {
    return {
		isMobile: store.isMobile,
	}
  },
  created () {
  },
  mounted () {
  },
  methods: {
	appear(){
		if(!this.isMobile){
			this.$refs.scramble1.appear()
			this.$refs.scramble1.decypher('in')
		}
		
		this.$refs.scramble2.appear()
		this.$refs.scramble2.decypher('in')

		this.$refs.scramble3.appear()
		this.$refs.scramble3.decypher('in')
	},
	hide(){
		if(!this.isMobile){
			this.$refs.scramble1.hide()
			this.$refs.scramble1.decypher('out')
		}

		this.$refs.scramble2.hide()
		this.$refs.scramble2.decypher('out')

		this.$refs.scramble3.hide()
		this.$refs.scramble3.decypher('out')
	},
	hover(){
		if(!this.isMobile){
			this.$refs.scramble1?.hover()
			this.$refs.scramble1?.decypher('in')
		}
		

		this.$refs.scramble2?.hover()
		this.$refs.scramble3?.hover()
		this.$refs.scramble2?.decypher('in')
		this.$refs.scramble3?.decypher('in')
	}


  }
}
</script>
<style lang="less">
	@import '../../assets/less/_imports.less';
	@import "./style.less";
</style>