<!-- eslint-disable vue/multi-word-component-names -->
<template>
	<div class="scramble-component" ref="val" v-html="val">
	</div>
</template>
<script>
import gsap from 'gsap'
import SplitType from 'split-type'
import E from '@/assets/js/utils/E'
import GlobalEvents from '@/assets/js/utils/GlobalEvents'
export default {
  name: 'Scramble-comp',
  components: {},
  props: {
    data: Object,
	val: String,
	appearTime: Number,
	type: String,
	afterAppear: Function
  },
  watch: {
	val(){
		this.$refs.val.split.revert()

		this.$nextTick(() => {
			this.$refs.val.split = new SplitType(this.$refs.val, { types: 'lines'})
			this.finalVal = this.$refs.val.split.lines.map(el => { return el.outerText})
			this.crypted()
		})
	}
  },
  data () {
    return {
		cryptedChar: '01234567890*=&!$%=+#<>0123456789*0=&!$%=+#<>0123456*7890=&!$%=+#<>012345*67890=&!$%=+#<>01234*567890=&!$%=+#<>',
		decypherSteps: 4,
		isAppeared: false, 
		totalChars: 0,
		animationComplete: false
		
	}
  },
  created () {
  },
  beforeUnmount(){
	E.off(GlobalEvents.RESIZE, this.onResize)
  },
  mounted () {
		this.$refs.val.split = new SplitType(this.$refs.val, { types: 'lines'})
		this.finalVal = this.$refs.val.split.lines.map(el => { return el.outerText})
		E.on(GlobalEvents.RESIZE, this.onResize)
		this.crypted()
  },
  methods: {
	appear(){
		this.appearTimeline = gsap.timeline()
		this.$refs.val.split.lines.forEach(el => {
			this.appearTimeline.to(el.chars, {opacity: 1, duration: 0.0, stagger: this.appearTime}, 0)
		})
		this.isAppeared = true
	},
	hover(){
		if(this.hoverTimeline) gsap.killTweensOf(this.hoverTimeline)
		this.hoverTimeline = gsap.timeline()
		this.$refs.val.split.lines.forEach(el => {
			gsap.set(el.chars, {opacity: 0})
		})
		this.$refs.val.split.lines.forEach(el => {
			this.hoverTimeline.to(el.chars, {opacity: 1, duration: 0.0, stagger: this.appearTime}, 0)
		})
	},
	hide(){
		this.appearTimeline = gsap.timeline()
		this.$refs.val.split.lines.forEach(el => {
			gsap.to(el.chars, {opacity: 0, duration: 0.0, stagger: this.appearTime})
		})
		this.isAppeared = false
	},
	decypher(way){
		const MAX_CELL_ITERATIONS = 5;
		let finished = 0;
		const loop = (line, char, iteration = 0) => {
            // cell.cache = {'state': cell.state, 'color': cell.color};
			const hoverOffset = this.type === 'hover' && way === 'in' ? parseInt(char.idNum) : 0
            if ( iteration >= MAX_CELL_ITERATIONS-1 + hoverOffset) {
				
				if(way === 'in'){
					char.innerHTML = char.dataset.original
					char.classList.add('animDone')
				} else {
					char.innerHTML = this.cryptedChar.split('')[Math.floor((this.cryptedChar.length - 1) * Math.random())]
					char.classList.remove('animDone')
				}

                ++finished;
                if ( finished === this.totalChars ) {
                    this.isAnimating = false;
					this.animationComplete = true
					if(this.afterAppear) this.afterAppear()
                }
            }
            else {
                // cell.set(this.getRandomChar());
				char.innerHTML = this.cryptedChar.split('')[Math.floor((this.cryptedChar.length - 1) * Math.random())]
            }

            ++iteration;
			const hoverOffsetEnd = this.type === 'hover' && way === 'in'  ? parseInt(char.completeId) : 0
            if ( iteration < MAX_CELL_ITERATIONS +  hoverOffsetEnd) {
                setTimeout(() => loop(line, char, iteration),  this.type === 'hover' && way === 'in'  ? 50 : this.getRandomInt(50,200));
            }
        };
		for (const [index, line] of this.$refs.val.split.lines.entries()) {
            for (const char of line.chars) {
				setTimeout(() => loop(line, char), (index+1)*80);
			}
        }
	},
	crypted(){
		this.$refs.val.split.lines.forEach((el, lineId) => {
			let out = el.innerHTML.split('').map(char => {
				char = this.cryptedChar.split('')[Math.floor((this.cryptedChar.length - 1) * Math.random())]
				return char
			})
			el.innerHTML = this.spanify(out.join(''))
			let chars = el.querySelectorAll('.char')
			Array.from(chars).forEach((el, charId) => {
				el.dataset.original = this.finalVal[lineId].split('')[charId]
			})

			el.chars = chars
			el.chars.forEach((elm, i) => { 
				elm.style.opacity = 0
				elm.idNum = i
				elm.completeId = el.chars.length
				this.totalChars++
			})
		});
	},
	spanify(text){
		const chars = text.split('')
		let out = ''
		chars.forEach(el => {
			out += '<span class="char">'
			out += el
			out += '</span>'	
		})
		return out
	},
	getRandomInt(min, max) {
		return Math.floor(Math.random() * (max - min + 1)) + min;
	},
	onResize(){
		this.$refs.val.split.revert()
		this.$nextTick(() => {
			this.$refs.val.split = new  SplitType(this.$refs.val, { types: 'lines'})
			this.finalVal = this.$refs.val.split.lines.map(el => { return el.textContent})
			this.totalChars = 0
			this.$refs.val.split.lines.forEach((el, lineId) => {
				let out
				if(!this.animationComplete){
					out = el.innerHTML.split('').map(char => {
						char = this.cryptedChar.split('')[Math.floor((this.cryptedChar.length - 1) * Math.random())]
						return char
					})
				} else {
					out = el.innerHTML.split('')
				}
			
				el.innerHTML = this.spanify( out.join('') )
				let chars = el.querySelectorAll('.char')
				Array.from(chars).forEach((el, charId) => {
					el.dataset.original = this.finalVal[lineId].split('')[charId]
				})

				el.chars = chars
				el.chars.forEach((elm, i) => { 
					elm.style.opacity =this.isAppeared ? 1 : 0
					elm.idNum = i
					elm.completeId = el.chars.length
					this.totalChars++
				})
			});
		})
		
	}
  }
}
</script>
<style lang="less">
@import '../../assets/less/_imports.less';
@import "./style.less";
</style>