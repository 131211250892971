<!-- eslint-disable vue/multi-word-component-names -->
<template>
	<div class="basic-component compEl main ops">
		<Menu />
		<div class="content">
			<svg viewBox="0 0 100 100" class="svgBlobs" v-if="!isSafari">
				<path :d="path" fill="white"></path>
			</svg>
			<div class="content--inner" ref="inner">
				<div class="fixed-part">
					<div class="head-part">
						<div class="first-container">
							<h1>
								<span class='caption'>/.2</span>
								<span class='caption'>Ops*</span>
							</h1>
							<div class="disclamer">
								<div class="disclamer_title">
									<span>{{ disclamer.title }}</span>
								</div>
								<div class="disclamer_content">
									<span class="disclamer_content--subtitle">{{ disclamer.subtitle }}</span>
									<div class="disclamer_content--input">
										<a :href="telegramLink" target="_blank">
											<input type="checkbox" id="scales" name="scales" />
											<label for="scales">{{ disclamer.input }}</label>
										</a>
									</div>
								</div>
							</div>
						</div>
						<div class="subtitle">
							<p v-html="subtitle"></p>
						</div>
					</div>
					<div class="map-container">
						<div class="heightCoord" v-for="item in isMobile ? 2 : 0" :key="item"> 
							<span>A</span>
							<span>B</span>
							<span>C</span>
							<span>D</span>
							<span>E</span>
						</div>
						<div class="widthCoord" v-for="item in isMobile ? 2 : 0"  :key="item" ref="widthCoord"> 
							<div class="num-container" :style="`transform: translate3d(${dragVal}px, 0, 0); width:${dragSize}px`">
								<span v-for="num in 10" :key="num">{{ num }}</span>
							</div>
							
						</div>

						<div class="map-part" ref="mapContainer">
							<img class="frame" :src="mapFrame" alt="">
							<img class="dotted" v-if="!isMobile" :src="dottedLayer" alt="">
							<div class="map-container" ref="map">
								<div class="place" v-for="(item, id) in places" :key="id"
									:class="[{ 'isActive': activePlaces.indexOf(item.country) !== -1 }, { 'isMain': item.country === mainPlace }]"
									:style="`left: ${50 + 50 * (item.coord[1] + mapOffset[0]) / 180}%; top: ${50 - 50 * (item.coord[0] + mapOffset[1]) / 87}%`">
									<span class="lines" v-for="item in 4" :key="item"></span>
									<span class="pin" @click="(e) => { clickMarker(item) }"></span>
									<span class="country">
										<ScrambleComp v-if="item.country === mainPlace" type="hover" :val="activeText"
											:appearTime="0.05" ref="scrambleComp" />
										<span class="hidden" v-html="activeText"></span>
									</span>
								</div>
								<img class="dotted" v-if="isMobile"  :src="dottedLayer" alt="">
								<img :src="map" alt="" ref="mapImage">
							</div>
						</div>
					</div>

					<div class="list-part">
						<table>
							<thead>
								<tr>
									<!-- <th></th> -->
									<th class="op" :colspan="isMobileBreak ? 2 : 1"><span>Operation</span></th>
									<th class="country" v-if="!isMobileBreak"><span>country</span></th>
								</tr>
							</thead>
							<tr class="item" v-for="(item, i) in ops" :key="i" :class="{ 'isActive': item.isActive }"
								@mouseover="(e) => { overtable(item) }" @click="(e) => { clicktable(item) }">
								<th class="index" v-if="!isMobileBreak">{{ i + 1 }}</th>
								<th class="op">{{ item.op }}</th>
								<th class="country" v-html="isMobileBreak ? item.countryMobile : item.country"></th>
							</tr>
						</table>
					</div>
				</div>
				<div class="bottom-part">
					<span class="toWrite" v-html="footerText"></span>
					<div class="right">
						<img :src="logo" alt="">
						<div class="coordinates toWrite" v-if="!isMobile">
							<span>{{ (cursorX * 10).toFixed(2)}} -BKD</span>
							<span>{{ (cursorY * 10).toFixed(2)}}°ST</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import blobshape from "blobshape";
import logo from "@/assets/images/logo.png"
import store from "@/assets/js/store";
import Menu from '@/components/Menu';
import mapFrame from '@/assets/images/map_frame.png'
import dottedLayer from '@/assets/images/dottedLayer.png'
import map from '@/assets/images/map.png'
import ScrambleComp from '@/components/ScrambleComp'
import gsap from 'gsap'
import Draggable from 'gsap/Draggable'
import E from '@/assets/js/utils/E'
import GlobalEvents from "@/assets/js/utils/GlobalEvents";

gsap.registerPlugin(Draggable)
export default {
  name: 'Ops-comp',
  components: {
	Menu,
	ScrambleComp
  },
  props: {
    data: Object
  },
  data () {
    return {
		path: '',
		isMobile: store.isMobile || store.window.w < 1025,
		isMobileBreak: store.isMobile || store.window.w < 813 ,
		mobileBreakpoint: 813,
		isSafari: store.isSafari,
		cursorX: 0.4,
		cursorY: -0.75,
		dragVal: 0,
		dragSize: 0,
		mapFrame: mapFrame,
		logo:logo,
		map: map,
		dottedLayer: dottedLayer,
		disclamer: {
			title: '*disclaimer',
			subtitle: 'OPeration DETAILS REDACTED //',
			input: 'Activate Groom Lake'
		},
		subtitle: '<span>Groom lake</span> differentiates itself as the only provider of global, on-the-ground exploit response through combined efforts with regional and international law enforcement groups.',
		places: [],
		mapOffset: [-15, -5],
		activePlaces: [],
		mainPlace: '',
		activeText: '',
		telegramLink: "http://t.me/Cerberus_GL",
		ops: [
			{
				op: 'northern light',
				country: 'uk/&#8203;canada',
				countryMobile: 'UK, CA',
				coord: [
					{ country: 'uk', coord: [55.483959, -2.244644]},
					{ country: 'canada', coord: [51.895077, -97.138451]}
				]
			},
			{
				op: 'wavefront',
				country: 'france',
				countryMobile: 'FR',
				coord: [
					{ country: 'france', coord: [48.864716, 1.]}
				]
			},
			{
				op: 'Euclidean mirage',
				country: 'saudi arabia/&#8203;Turkey',
				countryMobile: 'SA/TR',
				coord: [
					{ country: 'saudi arabia', coord: [24.774265, 46.738586]},
					{ country: 'turkey', coord: [41.015137, 28.979530]}
				]
			},
			{
				op: 'blood ruby',
				country: 'USA/&#8203;DUBAI',
				countryMobile: 'US/AE',
				coord: [
					{ country: 'usa', coord: [42.730610, -73.935242]},
					{ country: 'dubai', coord: [22.276987, 58.296249]}
				]
			},
			{
				op: 'WHITE MOUNTAIN',
				country: 'CANADA/&#8203;NETHERLANDS/&#8203;BOSNIA',
				countryMobile: 'CA/NE/BA',
				coord: [
					{ country: 'canada', coord: [51.895077, -97.138451]},
					{ country: 'netherlands', coord: [54.377956, 4.897070]},
					{ country: 'bosnia', coord: [43.856430, 18.413029]}

				]
			},
			{
				op: 'URAL SPECTRE',
				country: 'RUSSIAN FAR EAST/&#8203;NORTH KOREA',
				countryMobile: 'RU/KP',
				coord: [
					{ country: 'russian far east', coord: [48.10562, 131.873533]},
					{ country: 'north korea', coord: [41.019444, 128.738052]}
				]
			},
			{
				op: 'KOSHER RYE',
				country: 'Germany',
				countryMobile: 'DE',
				coord: [
					{ country: 'germany', coord: [52.520008, 11.404954]},
				]
			},
			{
				op: 'Sandy beach',
				country: 'Saudi arabia',
				countryMobile: 'SA',
				coord: [
					{ country: 'saudi arabia', coord: [24.774265, 46.738586]}
				]
			},
			{
				op: 'UNION GENERAL',
				country: 'USA',
				countryMobile: 'US',
				coord: [
					{ country: 'usa', coord: [42.730610, -73.935242]}
				]
			},

		],
		footerText: "Top Secret//HCS-O Dorians/SI-G oxgh//orcon/noforn"
	}
  },
  created () {
	if(!this.isSafari){
		let path = blobshape({ size: 100, growth: 1, edges: 10, seed: null });
		this.path = path.path
	}
  },
  beforeUnmount(){
	E.off('transitionEnd', this.setup)
	E.off(GlobalEvents.RESIZE, this.onResize)
	store.RAFCollection.remove(this.onRaf, 0)
  },
  mounted () {
	this.listPlaces()
	E.on(GlobalEvents.RESIZE, this.onResize)
	if(this.isMobile){
		setTimeout(() => {
			// this.$refs.map.style.width = this.$refs.mapImage.getBoundingClientRect().width + 'px'
			this.$refs.map.size = this.$refs.map.getBoundingClientRect()
			this.dragSize = this.$refs.map.size.width
			this.dragVal = -this.$refs.map.size.width * 0.5
			if(store.window.w < this.mobileBreakpoint){
				gsap.set(this.$refs.map, { x: -50+'%'})
			}

		})
	
	} else {
		store.RAFCollection.add(this.onRaf, 0)
	}
	E.on('transitionEnd', this.setup)
  },
  methods: {
	setup(){
		if(this.isMobile){
			this.$refs.mapContainer.size =  this.$refs.mapContainer.getBoundingClientRect()
			this.$refs.map.style.width = this.$refs.mapImage.getBoundingClientRect().width + 'px'
			this.$refs.map.size = this.$refs.map.getBoundingClientRect()
			this.dragSize = this.$refs.map.size.width
			this.dragVal = -this.$refs.map.size.width * 0.5
			this.initDrag()
		}
		this.clicktable(this.ops[0])

	},
	initDrag(){
		this.drag = Draggable.create(this.$refs.map, {
			type: "x",
			bounds: this.$refs.mapContainer,
			onDrag:() => {
				this.dragVal = this.drag[0].x
			}
		});
		if(store.window.w < this.mobileBreakpoint){
			gsap.set(this.$refs.map, { x: -50+'%'})
		}
		
		setTimeout(() => {
			this.$refs.map.size = this.$refs.map.getBoundingClientRect()
			this.dragSize = this.$refs.map.size.width
			this.dragVal = -this.$refs.map.size.width * 0.5
		}, 500)
	},
	listPlaces() {
		this.ops.forEach(el => {
			el.coord.forEach(coord => {
				this.places.push(coord)
			})
		})
		const ids = this.places.map(({ country }) => country);
		const filtered = this.places.filter(({ country }, index) => !ids.includes(country, index + 1));
		this.places = filtered
		this.places.forEach(el => {
			el.isActive = false
		})
		// this.places.push({country: 'centered', coord: [0, 0]})
		// this.places.push({country: 'north', coord: [90, 0]})

	},
	overtable(){

	},
	clickMarker(item){
		this.ops.forEach(el => {
			el.isActive = false
		})
		this.activePlaces = [item.country]
		this.mainPlace = item.country
		this.activeText = item.country
		this.ops.forEach(op => {
			op.coord.forEach(coord => {
				if(coord.country === item.country){
					op.isActive = true
				}
			})
		})

		setTimeout(() => {
			this.$refs.scrambleComp[0].appear()
			this.$refs.scrambleComp[0].decypher('in')
		}, 100)
		this.checkSize()
	},
	clicktable(item){
		this.ops.forEach(el => {
			el.isActive = false
		})
		item.isActive = true
		this.activePlaces = item.coord.map(el => { return el.country})
		this.mainPlace = item.coord[0].country
		this.activeText = this.isMobile ? item.op + ', ' + item.countryMobile : item.op+', ' + item.country
		// this.$refs.scrambleComp[]
		// this.places.forEach((el, i) => {
		// 	if(el.country === this.mainPlace){
				
		// 	}
		// })
		this.$nextTick(() => {
			// console.log(this.$refs.scrambleComp[0])
			
		})
		setTimeout(() => {
			this.$refs.scrambleComp[0].appear()
			this.$refs.scrambleComp[0].decypher('in')
		}, 100)
	
		

		if(this.isMobile){
			let drag = -(.5 + .5 * (item.coord[0].coord[1] + this.mapOffset[0]) / 180) * this.dragSize +  this.$refs.mapContainer.size.width * 0.2
			if(drag < (this.drag[0].minX - 100 )) {
				drag =  -(.5 + .5 * (item.coord[0].coord[1] + this.mapOffset[0]) / 180) * this.dragSize - 100 + this.$refs.mapContainer.size.width

			}
			drag = Math.max(drag,this.drag[0].minX )

			gsap.to(this.$refs.map, { x: drag, onUpdate: () => {
				this.drag[0].update()
			}})
		}

		this.checkSize()
	},
	checkSize(){
		this.$nextTick(() => {
			let countryCard = this.$el.querySelector('.isMain .country')
			countryCard.size = countryCard.getBoundingClientRect()
			this.$refs.map.size = this.$refs.map.getBoundingClientRect()
			
			if((countryCard.size.x + countryCard.size.width + 50) > this.$refs.map.size.x + this.$refs.map.size.width){
				// const offset = (countryCard.size.x + countryCard.size.width) - (this.$refs.map.size.x + this.$refs.map.size.width)
				// countryCard.style.transform = `translateX(-${offset}px)`
				countryCard.style.right = '20px'
				countryCard.style.left = 'auto'
				countryCard.style.bottom = '20px'
				countryCard.style.top = 'auto'
			} 
		})
	},
	onRaf(){
		this.cursorX = store.pointer.glNormalized.x
		this.cursorY = store.pointer.glNormalized.y
	},
	onResize(){
		this.$refs.map.size = this.$refs.map.getBoundingClientRect()
		this.dragSize = this.$refs.map.size.width
		this.isMobile = store.isMobile || store.window.w < 1025
		this.isMobileBreak = store.isMobile || store.window.w < 813 ,
		this.$refs.mapContainer.size =  this.$refs.mapContainer.getBoundingClientRect()
		if(this.isMobile && !this.drag){
			this.initDrag()
		} 
		if(this.isMobile){
			this.$refs.map.style.width = this.$refs.mapImage.getBoundingClientRect().width + 'px'
		} else {
			this.$refs.map.style.width = '96%'
		}
		if(!this.isMobile && this.drag){
			this.drag[0].kill()
			this.drag = undefined
			this.$refs.map.style.transform = ''
		}
		if(this.drag && this.drag[0]){
			console.log(this.drag[0].minX)
			this.drag[0].update(true)
		}
	}
  }
}
</script>
<style lang="less">
@import '../../assets/less/_imports.less';
@import "./style.less";
</style>