<!-- eslint-disable vue/multi-word-component-names -->
<template>
	<div class="braket-component">
		<span class="title">{{ data.title }}</span>
		<span class="val">{{ data.val }}</span>
	</div>
</template>
<script>
export default {
  name: 'braket-comp',
  components: {},
  props: {
    data: Object
  },
  data () {
    return {}
  },
  created () {
  },
  mounted () {
  },
  methods: {
  }
}
</script>
<style lang="less">
	@import '../../assets/less/_imports.less';
	@import "./style.less";
</style>