<!-- eslint-disable vue/multi-word-component-names -->
<template>
	<div class="layout">
		<div class="altitude">
			<div v-for="val in isMobile ? 1 : 2" :key="val" class="altitude--item">
				<div class="altitude--item_sub" :class="`item-${val}`" v-for="(item, id) in altitudeValues" :key="id">
					<div class="block">
						<span>{{ item.ft }}</span>
						<img :src="braket" alt="">
						<span>{{ item.m }}</span>
					</div>
					

					<span class="dash"></span>
					<span class="dash" v-if="!isMobile"></span>
					<span class="dash" v-if="!isMobile"></span>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import braket from '@/assets/images/braket.png'
import store from '@/assets/js/store'
export default {
  name: 'Basic-comp',
  components: {},
  props: {
    data: Object,
	secondStep: Boolean,
	altitude: Number
  },
  data () {
    return {
		braket: braket,
		isMobile: store.isMobile,
		altitudeEased: 0,
		altitudeValues: [
			{
				ft: '160FT',
				m: '48m'
			},
			{
				ft: '140FT',
				m: '42m'
			},
			{
				ft: '120FT',
				m: '36m'
			},
			{
				ft: '100FT',
				m: '30m'
			},
			{
				ft: '80FT',
				m: '24m'
			},
			{
				ft: '60FT',
				m: '18m'
			}
		]
	}
  },
  created () {
  },
  mounted () {

	this.fadeOffset = this.isMobile ? 100 : 200
	this.fadeSpeed = this.isMobile ? 0.05 : 0.01

	this.list1 = this.$el.querySelectorAll('.item-1')
	this.list2 = this.$el.querySelectorAll('.item-2')
	
	this.list1.forEach((el, id) => {
		el.dash = el.querySelectorAll('.dash')
		el.block = el.querySelector('.block')
		el.transformInit = (-(this.list1.length + 1) * 0.5 + id) * this.fadeOffset
		el.style.transform = `translate3d(${this.isMobile ? '-50%' : '0'} calc(${el.transformInit}px - 50%), 0)`

		el.dash.forEach((dash, i) => {
			dash.transformInit = 50 + i * 150/3
			dash.style.transform = `translate3d(-50%, ${dash.transformInit}px, 0)`
		})
	});
	this.list2.forEach((el, id) => {
		el.dash = el.querySelectorAll('.dash')
		el.block = el.querySelector('.block')
		el.transformInit = (-(this.list1.length + 1) * 0.5 + id) * this.fadeOffset
		el.style.transform = `translate3d(${this.isMobile ? '-50%' : '0'}, calc(${el.transformInit}px - 50%), 0)`
		el.dash.forEach((dash, i) => {
			dash.transformInit = 50 + i * 150/3
			dash.style.transform = `translate3d(-50%, ${dash.transformInit}px, 0)`
		})
	});

	store.RAFCollection.add(this.onRaf, 0)
  },
  methods: {

	onRaf(){
		this.altitudeEased += (this.altitude - this.altitudeEased) * 0.1


		this.fading = Math.min(Math.abs(this.altitude - this.altitudeEased) * 10, 1)
		this.$el.style.setProperty('--fade', this.fading)

		this.list2.forEach((el) => {
			el.transform = el.transformInit + this.altitudeEased * 10
			el.style.transform = `translate3d(${this.isMobile ? '-50%' : '0'}, calc(${el.transform}px - 50%), 0)`
			el.block.style.opacity = 1 - Math.max(0, Math.abs(el.transform) - this.fadeOffset) * this.fadeSpeed

			el.dash.forEach((dash) => {
				dash.style.opacity = (1 - Math.max(0, Math.abs(el.transform + dash.transformInit) - 200) * this.fadeSpeed) * this.fading
			})
			
		})
		this.list1.forEach((el) => {
			el.transform = el.transformInit + this.altitudeEased * 10
			el.style.transform = `translate3d(${this.isMobile ? '-50%' : '0'}, calc(${el.transform}px - 50%), 0)`
			el.block.style.opacity = 1 - Math.max(0, Math.abs(el.transform) - this.fadeOffset) * this.fadeSpeed


			el.dash.forEach((dash) => {
				dash.style.opacity = (1 - Math.max(0, Math.abs(el.transform + dash.transformInit) - this.fadeOffset) * this.fadeSpeed) * this.fading
			})
		})
	}
  }
}
</script>
<style lang="less">
	@import '../../assets/less/_imports.less';
	@import "./style.less";
</style>